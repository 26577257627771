<template>
  <div class="page">
    <div class="">
      <div class="page__title_main not-margin">
        Спасибо <br />
        за регистрацию!
      </div>
      <label class="page__title_desc">
        С заботой о наших любимых питомцах
      </label>
      <img :src="logoUrlComputed" class="finish-image" height="80%" width="80%" />
    </div>
    <a class="marginTop" :href="`/${uuid}`">
      <Button text="Перейти к питомцу"></Button>
    </a>
  </div>
</template>

<script>
import Logo from "@/assets/FourLapsFinish.png";
import Button from "@/components/common/Button.vue";

export default {
  name: "Finish",
  components: { Button },
  data() {
    return {
      uuid: "",
    };
  },
  computed: {
    logoUrlComputed() {
      return Logo;
    },
  },
  mounted() {
    this.uuid = this.$route.query.uuid;
  },
};
</script>

<style scoped lang="scss">
.marginTop{
  margin-top: 68px;
}

.finish-image{
  object-fit: contain;
  margin-top: 10px;
}
</style>
